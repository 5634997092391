body {
    background: #f5f5f5;
    background-image: url("https://boosterparis.com/images/main-slider/banner1.jpg");
    background-size: 100%;
}
 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

 
input[type=number] {
  -moz-appearance: textfield;
}  

#overlayNativeLogin {
    background: #f6fff7;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: .80;
  }
   
  #overlayNativeLogin .spinner {
      margin: 0 auto;
      height: 64px;
      width: 64px;
      animation: rotateNative 0.8s infinite linear;
      border: 5px solid rgb(0, 196, 55);
      border-right-color: transparent;
      border-radius: 50%;
  }
  @keyframes rotateNative {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }

.login-box {
    background-size: cover;
    background-color: #fff;
    border-radius: 10px;
    background-position: center;
    padding: 25px;
    margin: 50px auto;
    min-height: 600px;
    -webkit-box-shadow: 0 2px 60px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 60px -5px rgba(0, 0, 0, 0.1);
}

.logo {
    font-family: "Script MT";
    font-size: 54px;
    text-align: center;
    color: #888888;
    margin-bottom: 20px;
}

    .logo .logo-font {
        color: #3BC3FF;
    }



.header-title {
    text-align: center;
    margin-bottom: 50px;
}

.login-form {
    max-width: 300px;
    margin: 0 auto;
}

.login-form .form-control {
    border-radius: 6px;
    margin-bottom: 30px;
    color: #000000;
    font-size: 18px;
    background-color: #fff;
    border: 1px solid #4d540c;
    box-shadow: 0px 1px 8px #4d4f4e;
}

    .login-form .form-group {
        position: relative;
    }

        .login-form .form-group .forgot-password {
        position: absolute;
        top: 45px;
        right: 15px;
        }

    .login-form .btn {
        border-radius: 0;
        -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        margin-bottom: 30px;
    }

        .login-form .btn {
           margin-top:20px;
           border-radius:8px;
           width: 100%;
        }

    .login-form .btn a {
            color: #ffffff;
            text-decoration: none;
            font-size: 18px;
        }

.slider-feature-card {
    background: #fff; 
    margin: 0 auto; 
    text-align: center;
    -webkit-box-shadow: 0 2px 25px -3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 25px -3px rgba(0, 0, 0, 0.1);
}


    .slider-feature-card img {
    width: 400px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .slider-feature-card h3,
    .slider-feature-card p {
        margin-bottom: 30px;
    }

.slider-feature-card h3, .loginTitle {
    color: #275740;
    font-family: 'Roboto Slab', serif;
    font-weight:600;
    text-align:center;
    margin:15px;
}

.carousel-indicators {
    bottom: -50px;
}

    .carousel-indicators li {
        cursor: pointer;
    }

    .otp-field {
        display: flex;
    }
    
    .otp-field input {
        width: 45px;
        font-size: 24px;
        padding: 4px;
        text-align: center;
        border-radius: 5px;
        margin: 2px;
        border: 1px solid #55525c;
        background: #fbfbfb;
        font-weight: bold;
        color: #585858;
        outline: none;
        transition: all 0.1s;
    } 
    
    .otp-field input:focus {
        border: 2px solid #a527ff;
        box-shadow: 0 0 2px 2px #a527ff6a;
    }
    
    .disabled {
        opacity: 0.5;
    }
    .loginUrllog {
        text-align: center;
    }
    .loginUrllog h1 {
        font-size: 32px;
        color: green;
        text-align: center;
        padding: 25px;

    }
    .loginUrllog .checkmark {
        font-size: 40px;
        color: white;
        background-color: #25a6f1;
        padding: 10px 18px;
        border-radius: 50%;
        text-align: center;
        margin: 15px;
    }
    .loginUrllog p {
        margin-top: 20px;
    }
    .loginUrllog a {
        font-size: 20px;
        text-decoration: none;
        padding:   5px;
    }

    @media (max-width: 991.98px) {

        #overlayNativeLogin {
            background: #bafcc3;
            color: #666666;
            position: fixed;
            height: 100%;
            width: 100%;
            z-index: 5000;
            top: 0;
            left: 0;
            float: left;
            text-align: center;
            padding-top: 60%;
            opacity: .80;
          }

        .login-box {
            background-size: cover;
            background-color: #fff;
            border-radius: 10px;
            background-position: center;
            padding: 25px;
            margin: 75px auto;
            max-width: 80%;
            min-height: 500px;
            -webkit-box-shadow: 0 2px 60px -5px rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 60px -5px rgba(0, 0, 0, 0.1);
        }
     
    }
