 
body {
    font-family:   Arial, sans-serif !important;
    color: #4d5859;
    font-size: 14px;
}
body, h1, h2, h3, h6, h5, h4, p, span, label, input, div, .card, .card-body, li {
    font-family:   Arial, sans-serif !important; 
    
}
 p, input, div, .card, .card-body, li {
    font-family:   Arial, sans-serif  !important; 
    color: #4d5859;
}
.carousel-caption p {
    color: #fff;
}
.small-box p {
    font-size: 14px;
    margin-bottom: 2px;
}
.small-box h4 {
    font-size: 18px;
}
.master-form .form-group  .form-control, .master-form .form-group  .form-label {
    color: #4d5859;
}
.small-box p, .small-box h4, .Toastify div { 
    color: #fff;
}
.farmerLiveHomeUI .card-header .fa-minus {
    display: inline-block;
}
.farmerLiveHomeUI .card-header.collapsed .fa-minus {
    display: none;
}
.farmerLiveHomeUI .card-header .fa-plus {
    display: none;
}
.farmerLiveHomeUI .card-header.collapsed .fa-plus {
    display: block;
}
.btnGroupFoot {
    padding: 10px 2px;
}
.Toastify .Toastify__toast-container--bottom-center {
    pointer-events: none;
}

.topnav .search-container {
    float: right;
  }
  
  .topnav input[type=text] {
    padding: 6px;
    margin-top: 2px;
    font-size: 14px;
    border: none;
  }
  
.topnav .search-container button {
    float: right;
    padding: 6px 10px;
    margin-top: 2px;
    margin-right: 16px;
    background: #ddd;
    font-size: 14px;
    border: none;
    cursor: pointer;
}
.bpuFootBtn {
    padding: 15px;
    background-color: #eee;
}
.btnGroupFoot .btn {
    margin: 2px;
}
  .topnav .search-container button:hover {
    background: #ccc;
  }
.duname {
    padding: 5px;
    margin: 5px 0px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: 0px 0px 5px #bbb;
    border-left: 5px solid #ff781b;
}
#schemeSliderDiv .carousel-caption, #schemeSliderDiv .carousel-caption p {
    color: #27d4ff;
}
label.profNameSidebarUser {
    float: left;
    padding: 7px 0px;
    width: 170px;
    overflow: hidden;
}
 
.selectDistPayOrd {
    padding: 10px 5px;
    margin: 15px 0px 0px 0px;
    border-top: 1px solid #4b545c;
}
.selectDistPayOrd .form-label {
    color: #fff;
    padding: 0px 5px;
}
.selectDistPayOrd .form-control {
    background-color: #efefef;
    font-size: 15px;
    color: #434343;
}

.productListNewOrd label.pHeadName {
    padding-top: 4px;
}
.sideNavbarItem a.nav-link span, .profNameSidebar {
    color: #fff;
}
.sideNavbarItem {
    transition: 3s ease-out;
}
.navbar { 
    --bs-navbar-toggler-transition: box-shadow 0.15s none !important; 
    --bs-navbar-toggler-transition: none !important; 
    top: 0px;
}
a.botao-wpp-HomeIcon .fa-whatsapp.whatsappIconShare {
    color: #118501;
    font-size: 26px;
    float: right;
}
.errorScreenUI h4 {
    font-size: 16px;
    color: #878787;
    margin-top: 6%;
    background-color: #eee;
    padding: 15px;
    text-align: center;
}
div#accordionBatchWise .fa-plus.mcui {
    float: left;
    margin: 10px 2px;
    color: #006d9c;
}
div#accordionBatchWise .fa-minus.mcui {
    float: left;
    line-height: 36px;
    margin: 2px;
    color: #a10000;
}
#accordionBatchWise .form-control, #accordionBatchWise .form-control {
    box-shadow: 0px 1px 1px #717171 !important;
    border: 1px solid #cfcfcf !important;
}
.btn.btnInvT {  
    color: #fff;
    background-color: #257eff;
}
div#accordionBatchWise a.btn.collapsed {
    color: #3a3a3a;
}
div#accordionBatchWise .card {
    margin: 0px;
    border-radius: 0px;
}
div#accordionBatchWise .card-body {
    padding: 7px;
}
#inventoryPanelUI .card-body {
    padding: 0px;
}
.fa-minus.mcui {
    float: left;
    line-height: 36px;
    margin: 2px;
} 
span.deleteCartOrderItem {
    font: normal normal normal 14px / 1 FontAwesome !important;
}
.ftvw { 
    width: 100px;
    float: left;
} 
#advPayValueDistHead, #totalePayValueDistHead {
    width: 140px;
}
.bg-gray { 
    background-color: rgb(69 104 111) !important;
}
.bg-gray .card-title {
    color: #fff;
    font-weight: 600;
}
label.hdtextNty {
    color: #4e575b;
}
div#accListUI .card.hdtextNtyMain {
    padding: 5px;
    margin: 10px 0px;
    border: 1px solid #a8a8a8;
}
.iconbtn { 
    padding: 5px;
    margin: 0px 5px;
}
.iconbtn:hover { 
    padding: 0px; 
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.widget-user-username {
    margin-bottom: 2px;
}
.widget-user-username + p.card-title {
    font-size: 14px;
}
.btn-my {
    background-color: #ebebeb;
}
.file-drop-area {
    position: relative;
    display: flex;
    height: 45px;
    align-items: center;
    max-width: 100%;
    background-color: #f1f1f1;
    border: 1px solid rgb(69 69 69 / 80%);
    border-radius: 8px;
    transition: 0.2s;
    margin-bottom: 8px;
  }
  
  .choose-file-button {
    flex-shrink: 0;
    background-color: #0079f7;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    padding: 11px 12px;
    margin-right: 10px;
    color: white;
    font-size: 14px;
    min-width: 100px;
    text-align: center;
  }
  
  .file-message {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .orCenterText span { 
    position: absolute;
    background-color: #fff;
    padding: 0px 10px;
}

.orCenterText {
    text-align: center;
    padding: 13px 0px;
    border-bottom: 1px dashed #909090;
    margin-bottom: 15px;
}
.myBgFoot {
    background-color: #eee;
}
.compSwitchBTnBg { 
    text-align: center;  
}
.compSwitchBTnBg .card {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-bottom: 0px;
}
.card.noUI.accLedUI {
    margin-top: 0px;
}
.compSwitchBTnBg button {
    min-width: 100px;
}
  .file-input0 {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: -1111;
  }
  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
  }
  button.btn.btn-success.mycampbtn {
    width: 100%;
    height: 44px;
}

.master-section {
    position: relative;
    height: 100vh
}
img.img-responsive.eventImgSize {
    width: 100%;
}
.master-section .section-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}


.master-form {
    background: rgba(244, 244, 244, 0.9);
    padding: 40px;
    border-radius: 6px
}
form.master-form .row.card-body {
    padding: 0px;
}
.master-form .form-group {
    position: relative;
    margin-bottom: 15px
}
.master-form .form-control:focus {
    border-color: #b9b9b9 !important;
    outline: 0;
    background-color: #fff !important;
    box-shadow: 0 0 0 0.15rem rgb(10 108 254 / 25%) !important;
}
.master-form .form-control {
    background-color: #fcfafa;
    height: 47px;
    color: #191a1e;
    border: none;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #a5a5a5;
    box-shadow: none;
    border-radius: 8px;
    padding: 0px 5px;
}

.master-form .form-control::-webkit-input-placeholder {
    color: rgba(82,82,84,.4)
}

.master-form .form-control:-ms-input-placeholder {
    color: rgba(82,82,84,.4)
}

.master-form .form-control::placeholder {
    color: rgba(82,82,84,.4)
}

.master-form input[type=date].form-control:invalid {
    color: rgba(82,82,84,.4)
}

.master-form select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.master-form select.form-control+.select-arrow {
    position: absolute;
    right: 10px;
    bottom: 6px;
    width: 32px;
    line-height: 32px;
    height: 32px;
    text-align: center;
    pointer-events: none;
    color: rgba(0,0,0,.3);
    font-size: 14px
}

.master-form select.form-control+.select-arrow:after {
    content: '\279C';
    display: block;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

 
.btn-my.active {
    color: #ffffff;
    background-color: #0079f7;
    border-color: #0079f7;
}
 
.master-form .form-label {
    display: block;
    margin-left: 2px;
    margin-bottom: 5px;
    font-weight: 400;
    line-height: 24px;
    height: 24px;
    font-size: 16px;
    color: #323232;
}

.master-form .form-checkbox input {
    position: absolute!important;
    margin-left: -9999px!important;
    visibility: hidden!important;
    z-index: 1100;
}

.master-form .form-checkbox label {
    position: relative;
    padding-top: 1px;
    padding-left: 30px;
    font-weight: 400;
    color: #282828;
    font-size: 18px;
}

.master-form .form-checkbox label+label {
    margin-left: 15px
}

.master-form .form-checkbox input+span {
    position: absolute;
    left: 2px;
    top: 4px;
    width: 20px;
    height: 20px;
    background: #bebebe;
    border-radius: 50%;
}

.master-form .form-checkbox input+span:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #05a0ff;
    transition: .2s all;
    transform: translate(-50%,-50%);
}

.master-form .form-checkbox input:not(:checked)+span:after {
    opacity: 0
}

.master-form .form-checkbox input:checked+span:after {
    opacity: 1;
    width: 10px;
    height: 10px
}

.master-form .form-btn {
    margin-top: 27px
}

 
.workguidenceshareobs.master-form .submit-btn-sp {
    color: #fff; 
    font-weight: 400;
    height: 50px;
    font-size: 14px;
    border: none;
    width: 100%;
    border-radius: 40px;
    text-transform: uppercase;
    -webkit-transition: .2s all;
    transition: .2s all
}
.master-form .submit-btn {
    color: #fff;
    background-color: #008aaf;
    font-weight: 400;
    height: 47px;
    max-width: 350px;
    font-size: 16px;
    border: none;
    width: 100%;
    border-radius: 8px; 
    transition: .2s all;
}

.master-form .submit-btn:hover,.master-form .submit-btn:focus {
    opacity: .9
}


.nobornopad {
    padding: 0px;
    border: 0px;
}

.rmPdBor .card-body {
    padding: 0px;
    border: 0px;
}
.card.rmPdBor {
    border: 0px;
    box-shadow: none;
}

div#loaderFormSubmit {
    width: 100%;
    position: absolute; 
    opacity: 0.3;
    top: 0px;
    left: 0px;
    height: 100%;
    background-color: #000000;
    pointer-events: none;
}
#loaderScreen {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 80px;
    height: 80px;
    margin: -76px 0 0 -76px;
    border: 16px solid #679fff;
    border-radius: 50%;
    border-top: 16px solid #286600;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.accLedUI .flBtn.listfilterhead.active[data-filter="0"] {
    background-color: #eb0404 !important;
    color: #fff !important;
    font-weight: 500;
}
.accLedUI .flBtn.listfilterhead.active[data-filter="1"] {
    background-color: #18a300 !important;
    color: #fff !important;
    font-weight: 500;
}
.qtyNOW50 {
    width: 50px;
    padding: 3px;
}
.btnIconPadd {
    padding: 4px;
}
.accLedUI .flBtn.listfilterhead.active[data-filter="2"] {
    background-color: #f67979 !important;
    color: #424242  !important; 
}
.accLedUI .flBtn.listfilterhead.active[data-filter="3"] {
    background-color: #84fe82 !important;
    color: #424242  !important; 
}
.accLedUI .flBtn.listfilterhead.active[data-filter="4"] {
    background-color: rgb(69 104 111) !important;
    color: #ffffff  !important; 
}
.accLedUI .flBtn.listfilterhead.active[data-filter="all"] {
    background-color: #00BCD4 !important;
    color: #ffffff  !important; 
}

.fa.activeCartItemOrder {
    color: green;
    padding: 2px 8px;
}
label.accHeadName {
    padding: 0px;
    margin: 0px;
}
label.accAmountHead {
    margin: 0px;
    padding: 0px;
}
#accListUI table.table {
    margin-bottom: 0px;
}
.flr {
    float: right;
}
.w50r {
    width: 30%;
    text-align: right;
}
.w50 {
    width: 70%;
}
span.dtSmall {
    color: #7c7c7c;
    font-weight: 600;
    font-size: 12px;
}
label.accAmountHead.cr {
    color: #026e00;
    font-weight: 600;
    font-size: 18px;
}
label.accHeadName {
    padding: 0px;
    margin: 0px;
    font-weight: 600;
    color: #5f5f5f;
}
label.accAmountHead.dr {
    color: #930000;
    font-weight: 600;
    font-size: 18px;
}
 
.accHeadName {
    color: #0062ad;
}
a.btn.dp-right-mt5 {
    padding: 0px 2px;
}
div#accListUI .card {
    margin: 0px;
    color: #fff;
    padding: 0px;
    border: 0px;
    margin-bottom: 2px;
    border-radius: 0px;
    border-top: 1px solid #1f95ff;
    box-shadow: none !important;
}
div#accListUI {
    padding: 0px !important;
}
.card.noUI.accLedUI .card-header.noPad {
    padding: 0px;
}
.card.noUI.accLedUI .flBtn {
    width: 100%;
    float: left;
    padding: 5px 1px;
    background-color: aliceblue;
    border: 0px;
    border-right: 1px solid #5e5e5e;
    margin: 0px;
    border-radius: 0px;
    box-shadow: none;
    text-decoration: none;
}
.card.noUI.accLedUI .card-header {
    padding: 5px;
    background-color: #fff;
    border: 0px;
}
.card.noUI.accLedUI .listfilterhead.active {
    box-shadow: none ;
    background-color: #fff;
    color: #007bff;
}
.fa-whatsapp.whatsappIconShareFarmer {
    font-size: 24px;
    color: #0e7800;
    float: right;
}
.FIArea .info-box .info-box-text, .FIArea .info-box .progress-description {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.FIArea .info-box .info-box-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1.8;
    -ms-flex: 1;
    flex: 1;
    padding: 0 10px;
    overflow: hidden;
}
.FIArea .info-box .info-box-number {
    display: block;
    margin-top: .25rem;
    font-weight: 700;
}
.FIArea .info-box {
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    border-radius: .25rem;
    background-color: #fff;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1rem;
    min-height: 80px;
    padding: .5rem;
    position: relative;
    width: 100%;
}
.FIArea .info-box .info-box-icon {
    border-radius: .25rem;
    align-items: center;
    display: flex;
    font-size: 1.875rem;
    justify-content: center;
    text-align: center;
    width: 70px;
    color: white;
    padding: 12px;
}
.ma20 {
    margin-right: 20px;
}
.ma30 {
    margin-right: 68px;
}
.botao-wpp {
    text-decoration: none;
    color: #fff;
    display: inline-block;
    background-color: #25d366;
    font-weight: bold;
    padding: 4px 25px;
    border-radius: 3px;
    margin: 10px;
}
  .botao-wpp .whatsappIconShare {
    font-size: 28px;
}
  .botao-wpp:hover {
    background-color: #009437;
  }
  .addnewbka {
    font-size: 20px;
    padding-left: 10px;
    color: #006eb7;
    cursor: pointer;
}
  .botao-wpp:focus {
    background-color: #009136;
  }
  .NoDataAvailableText {
    text-align: center;
    background-color: #f1f1f1 !important;
    font-size: 16px;
    padding: 25px !important;
    color: #606060 !important;
}
label.pHeadNameLink {
    font-weight: 500;
    font-size: 16px;
    color: #fbfbfb;
}
.imgPrd50 {
    float: left;
    width: 30%;
}
.cntPrd50 {
    width: 69%;
    float: right;
    word-break: break-all;
}
p.pdetls {
    margin-bottom: 0px;
    padding: 8px;
    box-shadow: 0px 0px 2px #727272;
}
h6.subTitle {
    background-color: #d2d2d2;
    padding: 8px;
    font-weight: 600;
    margin: 5px 0px;
}
label.lblhead {
    font-weight: 500;
    width: 95%;
}
h6.subTitle.sinleIconDisplay + p.pdetls .col-12.combineProductIcon {
    display: block;
}
.col-12.combineProductIcon .PlusIcon {
    color: #dddddd;
    font-size: 39px;
}
.col-12.combineProductIcon {
    margin-bottom: -30px;
    text-align: center;
    display: none;
}
.card {
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    margin-bottom: 1rem;
}
.card-liveSuggCol:not(.card-outline)>.card-header {
    color: white;
    padding: 12px 10px;
    background-color: #098b60;
    border-radius: 0px;
    cursor: pointer;
}
.card-liveSuggCol:not(.card-outline)>.card-header .lblhead {
  
    cursor: pointer;
}

.card-warning:not(.card-outline)>.card-header {
    background-color: #ffc107;
}
.card-success:not(.card-outline)>.card-header {
    color: #ffffff;
    background-color: #0d8200;
}
.card-gray:not(.card-outline)>.card-header  {
    color: #FFF;
    background-color: rgb(69 104 111) !important
}
.card-danger:not(.card-outline)>.card-header  {
    color: #FFF;
    background-color: #F44336;
}
.card-primary:not(.card-outline)>.card-header {
    color: #FFF;
    background-color: #2196F3;
} 
 

#clickSubmitOrderAlertLogin {
    display: block;
}
#clickSubmitOrderAlertLogin.hide {
    display: none;
}

.dropdown.NOfilter a.dropdown-toggle {
    text-decoration: none;
    float: right; 
    margin-top: 5px;
}
nav.main-header.navbar.navbar-fixed-top {
    position: fixed;
    width: 100% !important; 
}
section.content {
    padding-top: 10px;
}
.small-box .icon i.fa {
    font-size: 28px;
    color: #dedede;
}
.addPartFC label {
    padding: 5px 2px;
    font-weight: 500;
    font-size: 16px;
}
.addPartFC .form-control {
    box-shadow: 0px 1px 1px #a9a9a9;
    margin-bottom: 7px;
}

.lfr {
    margin-left: 10px;
}
.lfrv {
    margin-left: 10px;
    margin-bottom: 5px;
}
.alert .close, .alert .mailbox-attachment-close {
    color: #000;
    opacity: .2;
}
.alert-dismissible .close, .alert-dismissible .mailbox-attachment-close {
    position: absolute;
    top: 0;
    font-size: 26px;
    right: 0;
    padding: 7px;
    z-index: 2; 
    color: red !important;
    font-weight: 800;
    box-shadow: none;
}
.alert-success {
    color: #fff;
    background-color: #28a745;
    border-color: #23923d;
}
.alert-warning {
    color: #1f2d3d;
    background-color: #ffc107;
    border-color: #edb100;
}
.fright {
    float: right;
}
.hide {
    display: none;
}
.card.noborder {
    border: 0px;
}
span#clickSubmitOrderAlert, span#SubmitOrderSuccessAlert {
    width: 80%;
    float: right;
}
.pad15lr {
    padding: 2px 15px;
}
.padBottomSubmit {
    padding: 15px;
    margin: 10px;
    box-shadow: 0px 0px 5px #898989;
    width: 98%;
}
.padBtnBto {
    padding: 25px;
}
.padBtnBto input {
    min-width: 150px;
}
.pad10lr {
    padding: 2px 15px;
}
label.pHeadName {
    font-weight: 500;
    font-size: 18px;
    color: #333;
}

.noUI label.pHeadName {
    font-weight: 500;
    font-size: 16px;
    color: #505050;
}
h5.setTtleHead  {
    padding: 10px;
    border-radius: 4px;
    color: white;
    font-weight: 600;
}
.backBtnBott .btnbackicon {
    font-size: 26px;
    color: #767676; 
    padding: 12px 20px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.rsRight {
    text-align: right;
}

/* .backBtnBott { 
    border: none; 
    box-shadow: 0 0 8px rgba(0, 0, 0, .18), 0 2px 6px rgba(0, 0, 0, .36);
    position: fixed;
    bottom: 20px;
    left: 20px;
    background: rgb(221 221 221 / 80%);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px; 
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
} */
.backBtnBott { 
    border: none; 
    position: fixed;
    bottom: -20px;
    right: 65px; 
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    border-radius: 35px;
    transition: all 0.3s ease;
}
.nptb0 {
    margin-bottom: 0px;
}
.headTTNo {
    border: 0px !important;
}
.faconProLinkNoMarg {
    float: right;
    padding: 0px 5px;
    font-size: 24px; 
}
.headTT div {
    font-weight: 500;
    padding: 5px 3px;
    border: 1px solid #eee;
}
.headTT1 div {
    font-weight: 500;
    padding: 5px 3px;
    background-color: #eee;
    border-top: 1px solid #5c5c5c;
}
.yes-padd-15 {
    padding: 15px;
}
.row.sepLn {
    box-shadow: 0px 0px 3px #bababa;
    padding: 2px;
    margin: 2px 0px;
    padding-top: 2px;
}
label.mandate.plusBankAlign {
    display: inline-block;
}
.row.sepLn div p {
    padding: 8px 0px;
    margin-bottom: 0px;
}
.mandate::after {
    content:"* ";
    color:rgb(182, 30, 30);
}
.ProductsHeadImgAcc {
    height: 3rem;
    width: 3rem;
    margin-right: 10px;
    padding: 0px; 
    border-radius: 1px;
    transition: margin-right .3s ease-in-out;
}
.qtyW50 {
    width: 70px;
}
.noUI .drop-in {
    display: none !important;
}
.accUIIcon {
    color: #fff;
    font-size: 22px; 
    margin-top: -5px !important;
}
.bgHeadPI label.prHeadName {
    font-weight: 500;
    font-size: 18px;
    color: #333;
}
.card-header {
    cursor: pointer;
}
.col-sm-12.bgHeadPI {
    border-top: 1px solid #bebebe;
    margin-top: 7px;
    cursor: pointer;
    padding-top: 7px;
}
.ProductsInfoHeadImg {
    height: 130px;
    width: 130px;
    display: block;
    margin: auto;
    text-align: center;
    padding: 0px;
    border-radius: 1px;
    transition: margin-right .3s ease-in-out;
}
.dumProdIcon {
    color: #838383;
    font-size: 16px;
    padding: 6px 6px;
    margin-right: 5px;
    border-radius: 22px;
    background-color: #fff3f3;
    box-shadow: 0px 0px 1px #868686;
}
.dumProdBigIcon {  
    color: #838383;
    font-size: 76px;
    padding: 6px 6px;
    margin-right: 5px;
    border-radius: 6px;
    box-shadow: 0px 2px 5px #868686;
    background-color: #fff3f3;
}
.dumProdBigIcon {
    color: #838383;
    font-size: 76px;
    padding: 6px 6px;
    margin-right: 5px;
    border-radius: 6px;
    box-shadow: 0px 2px 5px #868686;
    background-color: #fff3f3;
}
.centerImage {
    text-align: center;
}
h1.comesoon {
    color: #858383; 
    text-align: center;
    margin-top: 15%;
}
.profNameSidebar {
    float: left; 
    width: 170px;
    padding: 4px 0px;
    word-wrap: break-word;
}
.faconProLink {
    float: right;
    padding: 0px 5px;
    font-size: 24px;
    color: #bcbcbc;
    margin: 10px 2px;
}
.payDistHeadTransD {
    text-align: center;
    padding: 15px;
    color: #fff;
    margin: 15px 0px;
    background-color: #008402;
}
.invHead {
    background-color: rgb(69 104 111) !important;
    padding: 15px;
    text-align: center;
    color: white;
    margin: 5px 0px;
    margin-top: 15px;
    box-shadow: 1px 1px 5px #656565;
}
.noUI .listfilterhead.active { 
    box-shadow: 1px 1px 1px #37687a;
    background-color: #2689c9;
    color: #ffffff !important;
}
.noUI .card.itemfiltered.active {
    transition: margin-top .3s ease-in-out;
}
.noUI .itemfiltered.hide{
    display: none !important;
    transition: margin-top .3s ease-in-out;
}
.ProductsDetailsImgAcc {
    height: auto;
    max-width: 100px;
    margin-right: 5px;
    padding: 0px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)!important;
    border-radius: 10px;
    transition: margin-right .3s ease-in-out;
}
.noUI #accordion .dp-right-mt5 i.fa.fa-angle-double-up {
    display: none; 
}
.noUI #accordion .dp-right-mt5[aria-expanded="true"] i.fa.fa-angle-double-down {
    display: none;
    transition: ease-in-out;
}
.noUI #accordion .dp-right-mt5[aria-expanded="true"] i.fa.fa-angle-double-up {
    display: block;
    transition: ease-in-out;
}
.noUI #accordion i.fa.fa-angle-double-down, .noUI #accordion i.fa.fa-angle-double-up {
    color: #3c583c;
    font-size: 22px;
    transition: ease-in-out;
    cursor:pointer;
}
.dropdown.NOfilter ul.dropdown-menu {
    padding: 0; 
}
.dropdown.NOfilter input {
    box-shadow: 1px 1px 1px #7a7a7a;
}
#otpInputField.hide, #mobileNumField.hide {
    display: none;
}
input#searchProductFilter {
    min-width: 250px;
    box-shadow: 0px 0px 4px #757575;
}
.dropdown.NOfilter {
    width: 50px;
    float: right;
    margin-top: 5px;
}
.rightAlgin { 
    float: right; 
}
.sumryTxt {
    color: #242424;
}
#verifyOtpSubmit.hide, #requestOtpSubmit.hide {
    display: none;
}
.card.noUI .flBtn {
    width: auto;
    float: left;
    
    font-size: 14px;
    color: #424a4b;
    margin: 1px 2px;
    border: 1px solid #00d2ff;
    box-shadow: 1px 1px 1px #d0d0d0;
    text-decoration: none;
    padding: 3px 4px;
}
.loader {
    width: 215px;
    height: 215px;
    display: block;
    margin: auto;
    position: relative;
    background: #FFF;
    box-sizing: border-box;
  }
  .loader::after {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 15px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 100%),
     linear-gradient(#DDD 100px, transparent 0),
     linear-gradient(#DDD 16px, transparent 0),
     linear-gradient(#DDD 50px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 175px, 100% 100px, 100% 16px, 100% 30px;
    background-position: -185px 0, center 0, center 115px, center 142px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }
  
  @keyframes animloader {
    to {
      background-position: 185px 0, center 0, center 115px, center 142px;
    }
  }
  
.drop-in {
    animation: drop-in 1s ease 30ms backwards;
   }
    
   @keyframes drop-in {
    from {
     opacity: 0;
     transform: translateY(-20px);
    }
    to {
     opacity: 1;
     transform: translate(0px);
    }
   }
 .tempImgSize img {
    width: 240px;
    height: 180px;
 }
 .schemesUIDts p {
    margin-bottom: 5px;
 }

 .discRow {
    padding: 5px 0px;
 }
 .downPdfLk {
    font-size: 20px;
    padding: 2px 5px;
    color: red;
    cursor: pointer;
}
 .card-title {
    float: left;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 5px 0px;
}
 #SubmitPaymentFormContent,
 #PaymentDistributionFormContent,
 #PaymentSummaryDescriptionContent {
    display: none;
    transition: ease-in-out;
 }
 #SubmitPaymentFormContent.active,
 #PaymentDistributionFormContent.active,
 #PaymentSummaryDescriptionContent.active {
    display: block !important;
    transition: ease-in-out;
 }
 input#inputGroupFile01 {
    border: 1px solid #9a9a9a;
    border-radius: 10px;
    margin-top: 3px;
}
 .input-group-prepend span#inputGroupFileAddon01 {
    position: absolute; 
    left: -8px;
    background-color: #007ac4;
    color: #fff;
}
 .input-file {  
    position: relative;  
    display: block;  
    font-weight: 400;  
  }  
    
  .input-file .button {  
    position: absolute;  
    top: 4px;  
    right: 4px;  
    float: none;  
    height: 22px;  
    margin: 0;  
    padding: 0 14px;  
    font-size: 13px;  
    line-height: 22px;  
    background-color: #3276B1;  
    opacity: .8;  
    transition: opacity .2s;  
    -o-transition: opacity .2s;  
    -ms-transition: opacity .2s;  
    -moz-transition: opacity .2s;  
    -webkit-transition: opacity .2s;  
    outline: 0;  
    border: 0;  
    text-decoration: none;  
    color: #fff;  
    cursor: pointer;  
  }   
  .input-file .button input {  
    position: absolute;  
    top: 0;  
    right: 0;  
    padding: 0;  
    font-size: 30px;  
    cursor: pointer;  
    opacity: 0;  
  }  
  .input input {  
      display: block;  
      box-sizing: border-box;  
      -moz-box-sizing: border-box;  
      width: 100%;  
      height: 28px;  
      padding: 8px 10px;  
      outline: 0;  
      border-width: 1px;  
      border-style: solid;  
      border-radius: 0;  
      background: #fff;  
      font: 13px/16px 'Open Sans', Helvetica,Arial, sans-serif;  
      color: #404040;  
      appearance: normal;  
      -moz-appearance: none;  
      -webkit-appearance: none;  
  }   

 nav.agriheadBtm {
    display: none;
    position: fixed !important;
    margin-bottom: 0px !important;
    bottom: 0px;
    width: 100% !important;
    text-align: center;
    border-top: 1px solid #cecece;
    background: #f5f5f5 !important;
}
nav.navbar.fixed-bottom.agriheadBtm a {
    padding: 2px 2px 10px 2px;
    color: #636363;
    text-decoration: none;
}
nav.navbar.fixed-bottom.agriheadBtm a .mr-3 {
  font-size: 24px;
  margin: auto !important;
  display: block;
}
nav.navbar.fixed-bottom.agriheadBtm span.bnLabel {
  font-size: 14px;
}
nav.navbar.fixed-bottom.agriheadBtm a i {
    display: block;
    font-size: 22px;
    color: #026e00;
  }
  nav.navbar.fixed-bottom.agriheadBtm span.bnLabel {
    display: block; 
    text-decoration: none;
    font-weight: 600; 
    color: #373737;
    position: relative;
    transition: .3s padding-left ease;
  }
  .callout.callout-danger {
    border-left-color: #bd2130;
}
.callout.callout-info {
    border-left-color: #117a8b;
}
.callout.callout-success {
    border-left-color: #1e7e34;
}
.callout.callout-warning {
    border-left-color: #d39e00;
}
.callout {
    border-radius: .25rem;
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
    background-color: #fff;
    border-left: 5px solid #e9ecef;
    margin-bottom: 1rem;
    padding: 1rem;
}
  .dp-bred-head h1.m-0 {
    font-size: 22px;
    font-weight: 400; 
    padding-top: 13px;
    color: #454545;
}
.content-header.dp-bred-head {
    border-bottom: 1px solid #a4a4a4;
    margin-bottom: 10px;
}
.dp-bred-head ol.breadcrumb.dp-right {
    padding-top: 18px;
    margin-bottom: 15px !important;
}
.btn-100 {
    width: 100px; 
}
  .tbscroll {
    max-height: 500px;
    overflow: auto;
}
.MHSScroll {
    max-height: 300px;
    overflow: auto;
}
.dp-right-mt5 {
    float: right;
    margin-top: 5px;
}
#accordion1 .form-control, #accordion .form-control {
    box-shadow: 0px 0px 2px #717171;
    border: 1px solid #d8d8d8;
}
.form-control.payOutText {
    box-shadow: 0px 0px 2px #717171;
    border: 1px solid #d8d8d8;
}
.dp-right {
    float: right;
}
 .widget-user-image {
    float: left;
    height: auto;
    width: 65px;
}
.widget-user-image img {
    border-radius: 50%;
} 
.editMO {
    width: 100px;
    margin: 0px 5px;
}
.deleteCartOrderItem {
    right: 10px;
    position: absolute;
    font-size: 16px;
    color: #aa2c2c;
    font-weight: 300;
    padding: 12px;
    margin-top: -40px;
}
 .dpUI .dropdown-menu.show {
    max-height: 300px;
    max-width: 350px;
    min-width: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    float: none;
    left: inherit;
    right: 15px;
    transition-timing-function: ease-in;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
 }
 div#dashboardVideoSection {
    max-height: 790px;
    overflow: auto;
}
 .dpUI .dropdown-toggle::after {
    display: none;
 }
 .tH {
    min-height: 100px;
    text-align: center; 
 }
 .tH  .spinner-border {
    margin-top: 80px;
}
.progress-bar.Red, .badge.Red {
    background-color: #dc3545 !important;
} 
.progress-bar.DarkGreen, .badge.DarkGreen {
    background-color: #28a745 !important;
} 
.progress-bar.Orange, .badge.Orange {
    background-color: #ffc107 !important;
} 
.card {
    margin: 7px 0px;
}
.tempScroll {
    height: 200px;
    overflow: auto;
}
.sideNavbarItem a.nav-link:hover {
    transition: width ease-in-out .3s;
    text-decoration: none;
    background-color: rgba(255,255,255,.1);
}

.sideNavbarItem a.nav-link.active span {
    transition: margin-left .3s linear,opacity .3s ease,visibility .3s ease;
    padding-left: 5px;
}
.sideNavbarItem a.nav-link.active {
    background-color: #007bff;
    color: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
}
.sideNavbarItem a.nav-link span {
    transition: margin-left .3s linear,opacity .3s ease,visibility .3s ease;
    padding-left: 5px;
}
.sideNavbarItem a.nav-link { 
    color: #fff; 
    padding-right: 0px;
    padding-left: 10px;
}
nav.sideNavbarItem {
    padding: 10px 0px;
    width: 250px;
    max-height: 500px;
    overflow-y: auto;
    scrollbar-color: #a9a9a9 transparent;
}
.boostapp.active a.brand-link {
    padding: 15px 5px;
}
#accordion a.btn.collapsed {
    color: white;
}
.ProdimgBannerEvent {   
    width: 100%;
    height: auto;
    border-radius: 2px !important;
    padding: 0px;
    float: left;
    box-shadow: 0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)!important;
    border-radius: 25%;
    transition: margin-right .3s ease-in-out;
}
.ProdimgSmall {
    height: 3rem;
    width: 3rem;
    margin-right: 5px;
    padding: 0px; 
    box-shadow: 0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)!important;
    border-radius: 25%; 
    transition: margin-right .3s ease-in-out;
}
.user-panel .Profimg {
    height: 2rem;
    width: 2.1rem;
    box-shadow: 0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)!important;
    border-radius: 50%;
    margin: 10px;
    transition: margin-right .3s ease-in-out;
}
.user-panel {
    display: flex;
    width: 250px;
    border-bottom: 1px solid #4b545c;
}
.user-panel .info a {
    color: #fff;
    text-align: left;   
    text-decoration: none;
    line-height: 45px;
}
p#appendregistrationBenefitData p {
    margin-bottom: 0px;
}
.brand-link {
    text-align: center;
    width: 100%;
    display: block;
    border-bottom: 1px solid #4b545c; 
    padding: 8px;
}
.boostapp.active .main-sidebar span.BRSNav {
    display: none;
}
 .content-wrapper {
    background-color: #f4f6f9;
    height: 100%;
    min-height: calc(100vh - calc(1rem + 1px) - calc(1rem + 1px));
    transition: margin-left .3s ease-in-out;
    margin-left: 250px;
    padding-bottom: 100px;
    padding-top: 55px;
 }
 .boostapp.active .content-wrapper {
    margin-left: 4.6rem !important;
 }
 .boostapp.active .main-sidebar {
    margin-left: 0  !important;;
    width: 4.6rem  !important;;
}
.boostapp.active .barIconNav {
    margin-left: 4.6rem!important;
}
.boostapp.active .main-footer {
    margin-left: 4.6rem!important;
}

.barIconNav {
    transition: margin-left .3s ease-in-out;
    margin-left: 250px;
}

.main-sidebar {
    bottom: 0;
    float: none;
    left: 0;
    position: fixed;
    top: 0;
    padding: 0px; 
    overflow-y: hidden;
    z-index: 1038;
    background: linear-gradient(#008e1c, #004481) !important;
    color: rgba(255, 255, 255, 0.87) !important;
    box-shadow: 0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22)!important;
    transition: margin-left .3s ease-in-out,width .3s ease-in-out;
    width: 250px; 
}

.navbar-theme {
    background-color: #008e1c !important;
    color: rgba(255, 255, 255, 0.87) !important;
    border-bottom: 1px solid #dee2e6;
    z-index: 1034;
}
.navbar-theme .nav-link {
    color: rgb(255 255 255 / 100%) !important;
    padding-right: 1rem;
    padding-left: 1rem; 
    padding-bottom: 0.2rem;
    padding-top: .2rem;
    font-weight: 600;
    font-size: 20px; 
}
.navbar-right .navbar-badge {
    font-size: .6rem;
    font-weight: 300;
    padding: 4px 6px;
    position: absolute;
    margin-left: -5px;
    top: 26px;
    color: #fff;
    background-color: #dc3545;
}
 
.navbar-right {
    float: right;
    flex-direction: row;
}
.navbar-right .nav-link {
    color: rgb(255 255 255 / 100%) !important;
    padding-right: 1rem;
    padding-left: 1rem;  
    font-weight: 600;
    font-size: 20px;
    float: right;
}
.main-footer {
    background-color: #fff;
    border-top: 1px solid #dee2e6;
    color: #869099;
    padding: 1rem;
    transition: margin-left .3s ease-in-out;
    margin-left: 250px;
}
.boostapp.active .main-sidebar .nav-icon { 
    padding-left: 22px;
    padding-right: 24px;
}
.boostapp.active .user-panel .Profimg {
    margin: 10px 22px;
    transition: margin-left .3s ease-in-out;
}




input.form-control.IDInput {
    width: 75px;
    display: inline-block;
    box-shadow: 1px 1px 1px #959595;
    margin: 0px 2px;
}
.small-box {
    border-radius: .25rem;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    display: block;
    margin-bottom: 20px;
    position: relative;
}
.small-box>.inner {
    padding: 10px;
    min-height: 95px;
}
.scol1 {
    background-color: rgb(233, 30, 99) !important;
    color: rgb(255, 255, 255) !important;
}
.scol2 {
    background-color: rgb(255, 152, 0) !important;
    color: rgb(255, 255, 255) !important;
}
.scol3 {
    background-color: #009f0e !important;
    color: rgb(255, 255, 255) !important;
}
.scol5 {
    background-color: #EC5800 !important;
    color: rgb(255, 255, 255) !important;
}

.scol4 {
    background-color: #b61717 !important;
    color: rgb(255, 255, 255) !important;
}
.small-box .icon {
    color: #3b3b3b !important;
    position: absolute;
    right: 15px;
    font-size: 38px !important;
    top: 5px;
    transition: transform .3s linear;
}
.sbtnui .btn {
    width: 100%;
    padding: 7px 2px;
    margin: 3px 0px;
}
.small-box>.small-box-footer {
    background-color: rgba(0,0,0,.1);
    color: rgba(255,255,255,.8);
    display: block;
    padding: 3px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    z-index: 10;
}
.dropdown-item.nthl {
    font-size: 14px;
    padding: 8px;
    color: #434e50;
}
.dropdown-item.nthl .dp-right {
    float: right;
    font-size: 10px;
}
.dropdown-item.nthl  .fa-envelope {
    color: #209ff0;
    font-size: 18px;
    padding-right: 4px;
}
a.dropdown-item.dropdown-footer {
    background-color: #eee;
}
.noUI.schemDtsUI #accordion i.fa.fa-angle-double-down, .noUI.schemDtsUI #accordion i.fa.fa-angle-double-up
{
    color: #ffffff;
}
.noUI.schemDtsUI label.pHeadName {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
}
.schemDtsUI .dtss {
    padding: 0px 15px;
}
.schemDtsUI .dtss .col2, .schemDtsUI .dtss .col4 {
    box-shadow: 0px 0px 3px #c5c5c5;
    padding: 10px;
}
.col-lg-2 .small-box h3, .col-md-2 .small-box h3, .col-xl-2 .small-box h3 {
    font-size: 1.6rem;
}
.small-box p {
    font-size: 0.8rem;
}
.hdcolacc {
     
    background-color: rgb(247 247 247) !important;
}

.pheadBor {
    font-size: 24px !important;
    color: #4d4d4d;
    border-bottom: 2px solid #acacac;
    padding-bottom: 7px;
    margin-bottom: 20px;
}
a.d-block.ellipseTextWrap {
    line-height: 22px  !important;
}
.card.paydistheadCardnopad {
    margin: 0px;
    border-radius: 0px;
}
.mobpadpadpad {
    margin: 10px 0px;
}
form.master-form.edit-master-form {
    padding: 10px;
}
.payDistHead {
    text-align: center;
    padding: 15px;
    color: #fff;
    margin: 15px 0px;
    background-color:#006b9e;
}
.nopadPaydist {
    padding: 0px;
}
.hdcolacc a.btn {
    color: #2c2c2c !important;
    font-weight: 600;
}
.btnmptbl .btn {
    margin: 0px 5px;
    float: right;
}
.tbUIHd th {
    background-color: #eee !important;
}
.trPayByAmtHi {
    font-size: 12px;
}
span.trPayByCap {
    text-transform: capitalize;
}
.text-blue {
    color:#016ac4 !important;
    text-align: center;
    border-bottom: 1px solid #bee1ff;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #dddddd;
}
.text-grey {
    color: #555555 !important;
}
.text-grey-bold {
    color: #3b3b3b  !important;
    font-weight: 600;
}
.card-body.no-padding {padding: 0px  !important;}
table.table.table-hover.editPayList {
    border-top: 1px solid #c5c5c5;
}
.invLine{
    color: #478fcc !important;
}
#accordion .hdcolacc a.btn.collapsed {
    color: #2c2c2c;
}
table.table.table-hover.editPayList th {
    background-color: #e2edff;
}
.myOrdDets {
    background-color: #ededed;
    padding: 5px;
    border-radius: 4px;
    margin-top: 10px;
}
span.vdtsLink {
    color: white;
    cursor: pointer;
}
.drselopt {
    border: 1px solid #4b4b4b;
}
.sepSecLine { 
    padding: 5px 0px;
    box-shadow: 0px 0px 2px #bcbcbc;
}
.sepSecLineOutAmt {
    padding: 7px 0px;
    box-shadow: 0px 0px 2px #bcbcbc; 
    padding-bottom: 15px;
}
 
@media (max-width: 991.98px) {
   
    .topnav .search-container {
        float: left;
    }
    .content-wrapper  {
        margin-left: 0;
    }
    .barIconNav {  
        margin-left: 0;
    }
    .main-sidebar {
        margin-left: 0; 
        box-shadow: none !important;
        bottom: 0;
        float: none;
        left: 0;
        position: fixed;
        top: 0;
        margin-left: -250px;
    }
    .boostapp.active .main-sidebar {
        margin-left: 0 !important;
        width: 250px !important;
        box-shadow: 0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22)!important;
        transition: margin-left .3s ease-in-out,width .3s ease-in-out; 
    }
    .boostapp.active .content-wrapper,  .boostapp.active  .main-footer  {
        margin-left: 0 !important;
    }
    .main-footer  {
        margin-left: 0 !important;
    }
    .dpUI .dropdown-menu.show { 
        right: 5px; 
     }
     nav.agriheadBtm { 
        display: flex  !important;
     }
    .col-sm-6.hide-on-mobile {
        display: none;
    }
    .card-body .small-box .icon {
        font-size: 24px !important;
    }
    .carousel-caption {
        position: absolute;
        right: 15%;
        bottom: 0.25rem;
        left: 15%;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        color: #fff;
        text-align: center;
    }
    .card-body.myMobViewTable { 
        overflow-y: scroll;
    }
    .noUI label.pHeadName {
        font-weight: 500;
        font-size: 16px;
        padding-top: 8px;
        color: #333;
        line-height: 16px;
        max-width: 140px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
     .productListNewOrd label.pHeadName {
        font-weight: 500;
        font-size: 16px;
        padding-top: 8px;
        color: #333;
        line-height: 16px;
        max-width: 140px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    
    .carousel-caption h4 {
        font-size: 15px;
        margin-bottom: 4px;
    }
    .carousel-caption p {
        font-size: 12px;
        margin-bottom: 4px;
    }
    .content-wrapper .container-fluid .row:first-child .col-slg-12 {
        padding: 0px  !important; 
    }
  
    .boostapp.active .main-sidebar .nav-icon {
        padding-left: 6px;
        padding-right: 6px;
    }
    .boostapp.active .main-sidebar span.BRSNav {
        display: inline-block !important;
    }
    .sideNavbarItem a.nav-link {
        color: #fff;
        padding-left: 2px;
        padding-right: 2px;
    }
    input.form-control.IDInput {
        width: 45px !important;
        display: inline-block;
        box-shadow: 1px 1px 1px #959595;
        margin: 0px 2px;
    }
    .btn-block100
    {
         
        margin: 2px 5px;
    }
    .ftvw { 
        float: left;
    } 
    .mtp td {
        text-align: center;
    }
    .fa-minus.mcui {
        float: none; 
    }  
    .whe110 {
        height: 140px !important;
    }
    nav.sideNavbarItem {
        width: 270px !important;
    }
    div#dashboardVideoSection {
        max-height: 500px;
        overflow: auto;
    }
    .small-box .icon i.fa {
        font-size: 20px !important;
        color: #dedede;
    }

    .padBtnBto {
        padding: 25px 5px !important;
    }
    .padBtnBto input {
        min-width: 100% !important;
    }
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl, .row {
        --bs-gutter-x: 0.5rem !important;
    }
    div#accListUI {
        padding: 0px !important;
    }
    .card-body {
        padding: 0.5rem !important;
    }
    .master-form.edit-master-form {
        padding: 0px 0px !important;
    }
    .master-form { 
        padding: 15px 10px !important;
    }
    .bs-stepper {
        width: 99%;
    }
    .errorScreenUI h4 {
        font-size: 16px;
        color: #878787; 
        text-align: center;
    }
    .profNameSidebar {
        float: left;
        width: 146px  !important;
        word-wrap: break-word;
    }
    .card-header.headerNewOrderFlt {
        padding: 5px;
    }
    .small-box h4 {
        font-size: 16px;
    }
    
} 

.accFooBtn .btn-block100
{ 
    margin: 2px 10px;
}
.col-md-12.accFooBtn {
    margin-top: 50px;
}
.fa-search.prdSear {
    font-size: 18px;
    color: #507590; 
}
.dropdown.NOfilter .dropdown-toggle::after {
    display: none;
}
.card-header.headerNewOrderFlt {
    padding: 8px;
}
.submit-btn-dis-pay {
    color: #fff;
    background-color: #008aaf;
    font-weight: 400;
    height: 47px;
    max-width: 350px;
    font-size: 16px;
    border: none;
    width: 100%;
    border-radius: 8px;
    transition: .2s all;
}
.workguidenceshareobs.master-form {
     
    padding: 25px !important;
    border-radius: 0px !important;
    color: #fff;
    font-weight: 600;
}
.workguidenceshareobs.master-form textarea {
    height: 100px;
}
.workguidenceevents.master-form {
    
    padding: 25px !important;
    border-radius: 0px !important;
}
.workguidenceeventsRecipt.master-form {
    
    padding: 25px !important;
    border-radius: 0px !important;
}
.whtext {
    color: #fff !important;
    font-weight: 600;
}
.form-control.txtDetailsHt {
    height: 80px;
}
.arrow-down-sidebar {
    width: 0px;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #e1e1e1;
    margin-top: 10px;
    opacity: 0.5;
    margin-left: 85px; 
    position: absolute;
    transition: ease-in;
}
.arrow-up-sidebar  {
    width: 0px;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #e1e1e1;
    margin-top: 10px;
    opacity: 0.5;
    margin-left: 85px; 
    position: absolute;
    transition: ease-in;
}
.btn-app>.fa, .btn-app>.fab, .btn-app>.fad, .btn-app>.fal, .btn-app>.far, .btn-app>.fas, .btn-app>.ion, .btn-app>.svg-inline--fa {
    display: block;
    font-size: 20px;
}
.custom-file .custom-file-input {
    box-shadow: 0px 0px 2px #049cff;
    border: 1px solid #bababa;
    margin: 5px;
    background-color: #f6f6f6;
}
.btn-app {
    border-radius: 3px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    color: #ffffff;
    font-size: 16px;
    min-height: 100px;
    margin:5px 0px; 
    padding: 10px;
    position: relative;
    text-align: center;
}

.bg-pending
{
    background-color: red !important;
      color: black !important;
}

.bg-pending  h4
{
      color: #fff !important;
      font-size: 0.9rem !important;
}

.bg-approved
{
    background-color: #ffc1078c !important;   
}

.bg-approved  h4
{
      color: black !important;
      font-size: 0.9rem !important;
}

.bg-approved  p
{
      color: black !important;
}

.bg-processing
{
    background-color: #ffc1078c !important;
}

.bg-processing  h4
{
      color: #fff !important;
      font-size: 0.9rem !important;
}

.bg-completed
{
    background-color: #ffc1078c !important;    
}

.bg-completed  h4
{
      color: #fff !important;
      font-size: 0.9rem !important;
}

.bg-inprocess
{
    background-color: #d4ef508c !important;   
}

.bg-inprocess  h4
{
      color: black !important;
      font-size: 0.9rem !important;
}

.bg-inprocess  p
{
      color: black !important;
      font-size: 0.9rem !important;
}

.bg-confirmed
{
 background-color: darkgreen !important;  
}
.bg-confirmed  h4
{
      color: #fff !important;
      font-size: 0.9rem !important;
}
.bg-error
{
 background-color: #f50529e7 !important;  
}
.bg-error  h4
{
      color: #fff !important;
      font-size: 0.9rem !important;
}
.bg-rejected
{
 background-color: #ff39078c !important;
}
.bg-rejected  h4
{
      color: #fff !important;
      font-size: 0.9rem !important;
}

.bg-readyForDispatch
{
 background-color: #90e9328c !important; 
}
.bg-pending  h4
{
      color: #fff !important;
      font-size: 0.9rem !important;
}

.bg-dispatchedPartially
{
     background-color: blue !important;    
}
.bg-dispatchedPartially  h4
{
      color: #fff !important;
      font-size: 0.9rem !important;
}

.bg-dispatched
{
     background-color: blue !important;      
}
.bg-dispatched  h4
{
      color: #fff !important;
      font-size: 0.9rem !important;
}

.bg-processing
{
     background-color: #a0b389 !important;      
}
.bg-processing  h4
{
      color: black !important;
      font-size: 0.9rem !important;
}
.bg-processing  p
{
      color: black !important;
}

.smallfontindicator {
    font-size: 0.9rem !important;
}

.small-box p {
    font-size: 1.2rem;
}

.bg-warning  h4
{
      color: black !important;
      font-size: 0.9rem !important;
}

.bg-warning  p
{
      color: black !important;
}

.bg-approved
{
    background-color: #ffc1078c !important;   
}

.indicatorBoxH3
{
   color:white;
}

.arrowDark {
        font-size: 20px !important;
        color: green !important; 
    }

    
.arrowLittleDark {
        font-size: 20px !important;
        color: green !important; 
    }